import React from 'react'

const ErrorPage = () => {
  return (
    <div style={{width:"100%",height:"100vh",display:'flex',justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
      <h1>UPS!</h1>
      <h2>Üzgünüz Aradığınız Sayfa Bulunamadı!</h2>
    </div>
  )
}

export default ErrorPage

import logo from './logo.svg';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import ActivationPage from './pages/ActivationPage';
import ErrorPage from './pages/ErrorPage';
import ActivationCompleted from './pages/ActivationCompleted';

function App() {
  return (
    <Routes> 
      <Route path='/activationcompleted' element={<ActivationCompleted></ActivationCompleted>}></Route>     
      <Route path='/activation/:token' element={<ActivationPage></ActivationPage>}></Route>
      <Route path='*' element={<ErrorPage></ErrorPage>}></Route>
    </Routes>
  );
}

export default App;

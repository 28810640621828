import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useNavigate, useParams } from 'react-router-dom'

const ActivationPage = () => {

    const { token } = useParams();
    const Navigate = useNavigate();

    useEffect(() => {
        axios.get(`http://neredenaldin.eu-central-1.elasticbeanstalk.com/api/v1/user/ActivateUser/${token}`)
            .then(response => {
                console.log(response.data)
                if (response.data) {
                    Navigate("/activationcompleted");
                }
            })
            .catch(err => console.log(err))
    }, [])

    return (
        <div style={{ width: "100%", height: "100vh", display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <h2>Neredenaldın Dünyasına Hoş Geldiniz!</h2>
            <h3>Hesabınız Aktif Ediliyor Lütfen Bekleyin...</h3>
        </div>
    )
}

export default ActivationPage

import React, { useEffect } from 'react'

const ActivationCompleted = () => {
    

    return (
        <div style={{ width: "100%", height: "100vh", display: 'flex', justifyContent: "center", alignItems: "center", flexDirection: "column" }}>
            <h2>Hesabınız Aktif Edildi..</h2>
        </div>
    )
}

export default ActivationCompleted
